import axios from 'axios'

export const init = (options) => {
    const cloud_axios = axios.create(Object.assign({
        withCredentials: true,
        _showLoading: true,
        headers: {
            'Content-Type': 'text/plain; charset=utf-8',
        },
    }, options))

    // 请求前和结果返回后的中间件
    cloud_axios.interceptors.request.use(
        config => {
            if (config._showLoading) {
                //Vue.prototype.$tip({msg: "正在加载....", type: "loading"});
            }
            return config
        },
        error => {
            return Promise.reject(error)
        }
    )

    cloud_axios.interceptors.response.use(
        response => {
            //Vue.prototype.$tip.close();

            let body = response.data
            // 这里统一处理登录鉴权失效的逻辑
            if (body.code === 1 || body.code === -1) {
                //login.show();
                body.message = '会话过期, 请先登录'
            }

            return response
        },
        error => {
            // Vue.prototype.$tip.close();
            return Promise.reject(error)
        }
    )

    return cloud_axios
}
