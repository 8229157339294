/* 
 *异步的数据发送模块
 *@author hazelchen
 */
import Dao from './dao'
import request from './request'

let dao
//声明一个获取数据的class
const Data = class {
    constructor(opts) {
        this.opts = opts;
    }

    getData() {
        let pro;
        switch (this.opts.dataMap.type) {
            case 'meteor':
                pro = this.getzZConfigerData();
                break;
            default:
                pro = this.getAjaxData();
                break;
        }
        return pro;
    }

    ajax() {
        let eachData = this;
        return new Promise((resolve, reject) => {
            let opts = eachData.opts.dataMap;
            //转换dcapi
            if (opts.dcapi) {
                opts.fromId = opts.dcapi.fromId;
                opts.toId = opts.dcapi.toId;
                opts.mdInterfaceId = opts.dcapi.interfaceId;
            }

            dao.request(opts).then(function (d) {
                let data = d.data || {};
                if (eachData.opts.dataMap.noCheckData) {
                    resolve(data)
                } else {
                    if (data.code === undefined) {
                        data.code = data.ret;
                    }

                    if (data.message === undefined) {
                        data.message = data.msg || '请求失败，请稍候再试';
                    }
                    //塞一个公共的
                    data.errorMsg = data.message;
                    if (data.code === 0) {
                        resolve(data.data);
                    } else if (eachData.opts.dataMap.noDefinitelyNeed) {
                        resolve(undefined);
                    } else {
                        reject(data);
                    }
                }

            }).catch(function (data = {}) {
                if (eachData.opts.dataMap.noDefinitelyNeed) {
                    resolve(undefined);
                } else {
                    reject({
                        data: data.data,
                        code: 500,
                        errorMsg: data.msg || '请求失败，请稍候再试'
                    });
                }
            });
        })
    }

    getzZConfigerData() {
        let eachData = this
        return new Promise((resolve, reject) => {
            dao.getZZData(eachData.opts.dataMap.flag1, eachData.opts.dataMap.flag2).then((data) => {
                if (data && data.data) {
                    resolve(data.data)
                } else if (eachData.opts.dataMap.noDefinitelyNeed) {
                    resolve(undefined);
                } else {
                    reject({
                        code: 500
                    });
                }
            }).catch(_ => {
                if (eachData.opts.dataMap.noDefinitelyNeed) {
                    resolve(undefined);
                } else {
                    reject({
                        code: 500
                    });
                }
            })
        })
    }

    getAjaxData() {
        return this.ajax();
    }
}

/**
 * 
 * @param {Object} opts 详细的见./request 里面的接口说明
 */
export async function getData(opts = {}) {
    opts.Data = Data;
    //有个默认错误的触发
    if (!opts.onError) {
        opts.onError = (data) => {
            console.error(data)
        }
    }
    return request.getData(opts);
};

/**
 * 
 * @param {Object} opts opts为axios的create的config
 */
export function initModel(opts = {}) {
    opts.imgcache = window.IOT && window.IOT.imgcache || 'imgcache.qq.com'
    dao = new Dao(opts)
}
