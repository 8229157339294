import qcloudUtil from '../util/qcloudUtil'
import {
	init
} from './common'
import {
	jsonpAdapter
} from '../util/jsonpAdapter'

class Dao {
	constructor(options) {
		this.opts = options

		//初始化util,用以获取cookie
		qcloudUtil.init()
		this.cloud_axios = init(options)
	}

	request (options = {}) {
		let self = this,
			_data = options.data
		return new Promise((resolved, reject) => {
			//server bundle add http,client bundle not change it.
			let httpHeader = self.opts.baseURL.match(/([\w:]*)\/\//)
			if (httpHeader[1]) {
				options.url = options.url.replace(/^\/\//, `${httpHeader[1]}//`)
			}
			let finalAction = options.url

			if (qcloudUtil.getUin())
				finalAction = `${options.url}${!~options.url.indexOf('?') ? '?' : '&'}g_tk=${qcloudUtil.getACSRFToken()}`
			_data.RequestId = `${Date.now()}-${qcloudUtil.getUin()}`

			try {
				//优先使用post吧
				delete options.data
				self.cloud_axios[options.method || 'post'](finalAction, _data, options).then(data => {
					resolved(data)
				})
			} catch (obj) {
				reject(obj)
			}
		})
	}
	//暂时只有异步里面掉用
	getZZData (flag1, flag2) {
		if (!flag1 || !flag2) return

		let self = this
		let url = `${this.opts.imgcache}/qzone/qzactStatics/qcloud/data/${flag1}/config${flag2}.js`

		return self.cloud_axios({
			url,
			adapter: jsonpAdapter,
			callbackName: `callback_${flag1}_config${flag2}`
		})
	}
}

export default Dao
