/*
 *直出/异步 统一处理请求的依赖等等
 *@author hazelchen
 */
let Data, 
api;

/*
 *将一个字符串变成数组
 */
const arrify = function (value) {
    if (value == null) return [];
    return Array.isArray(value) ? value : [value];
};


const preHandler = (datasets, val, level, handlered) => {
    var dataset = datasets[val];
    if ((handlered[val] !== undefined && handlered[val] < level) || handlered[val] === undefined) {
        handlered[val] = level;
    }

    if (dataset.dependencies && dataset.dependencies.length) {
        level = level + 1;
        dataset.dependencies.forEach(function (value) {
            preHandler(datasets, value, level, handlered);
        });
    }
};

const getHandledData = (dataset, api) => {
    if (Array.isArray(dataset)) {
        return [dataset.map(d => {
            return api[d] || {};
        })];
    }

    var handlered = {};
    for (let i in dataset) {
        if (i !== 'handlerData') {
            preHandler(dataset, i, 0, handlered);
        }
    }
    var requestArr = [];

    for (var k in handlered) {
        requestArr[handlered[k]] || (requestArr[handlered[k]] = []);
        requestArr[handlered[k]].push(api[k] || {});
    }

    return requestArr;
};



const requestData = (params, dataset, level, ret, resolve, reject) => {
    if (level < 0) {
        resolve(ret);
    } else {
        var execedDataSet = dataset[level].map(dc => {
            return dc.apply(api, [params, ret]);
        }).filter(d => {
            // 去掉false的
            return !!d;
        });

        Promise.all([...execedDataSet.map(dc => {
            return new Data(dc).getData();
        })]).then(function (values) {
            // 处理下数据
            values.map((value, i) => {
                if (value !== undefined) {
                    execedDataSet[i].handlerDataFun(value, ret);
                }
            });
            requestData(params, dataset, level - 1, ret, resolve, reject);
        }).catch((data) => {
            reject(data);
        });
    }
};

const handlerDataset = function (dataset, api) {
    // 因为dataset可能有三种形式
    let handledData;
    if (Object.prototype.toString.call(dataset) === '[object Object]') {
        handledData = getHandledData(dataset, api);
    } else {
        handledData = getHandledData(arrify(dataset), api);
    }
    return handledData;
};

const request = function (params, dataset, opts) {
    api = opts.api;
    return new Promise((resolve, reject) => {
        var ret = {};
        requestData(params, dataset, dataset.length - 1, ret, resolve, reject);
    }).catch((data) => {
        let err;
        if (data.code) {
            // 预处理一下
            err = api.errorCodeMap ? api.errorCodeMap(data) : data;
        } else {
            err = {
                code: '500'
            };
        }
        if (opts.params && opts.params.onError) {
            opts.params.onError(err);
        } else if (opts.onError) {
            //兼容方案
            opts.onError(err);
        }
    });
};

/*
 *根据cgi的依赖以及配置返回拼接好的数据
 *@param  opts{Object}
 *opts.Data {Class} 处理数据方式
 *opts.api{Object} 数据配置，包括数据参数和处理方式
 *opts.dataset {Object | Array | string}object表示有依赖的情况,array表示并发，string表示单个请求
 *@example1
 dataset: {
        getPageData: {
          dependencies: false
        },
        getQbossData: {
          dependencies: false
        },
        getStarData: {
          dependencies: ['getPageData']
        },
        getRecommendData: {
          dependencies: ['getQbossData']
        }
}
*example2
dataset: ['getPageData', 'getQbossData', 'getStarData', 'getRecommendData']
*@example3
dataset: 'getPageData'

 *opts.params {Object}传给cgi配置的参数
 *opts.params.onError {function} 错误的时候方法，可以做统一处理，先暂定业务方处理吧
 *@return ret {object}
 */
export default {
    async getData(opts) {
        Data = opts.Data;
        api = opts.api;
        let handled = handlerDataset(opts.dataset, api);
        let handledData = await request(opts.params, handled, opts);

        return handledData;
    }
};
