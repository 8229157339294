/**
 * Created by tomcomtang on 2018/12/18.
 */
import Cookies from 'universal-cookie'
let csrfCode = ''

/**
 * 工具类
 * @class util
 * @static
 */
let util = {
    cookie: {
        get() {

        },
        set() {

        }
    },
    init() {
        if (process.server) {
            this.cookie = new Cookies(window.request.headers.cookie)
        } else {
            //console.log('在浏览器环境中')
            this.cookie = new Cookies()
        }
    },
    getACSRFToken() {
        if (!csrfCode) {
            var s_key = this.getSessionKey()
            if (!s_key) {
                return ''
            }
            var hash = 5381
            for (var i = 0, len = s_key.length; i < len; ++i) {
                hash += (hash << 5) + s_key.charCodeAt(i)
            }
            csrfCode = hash & 0x7fffffff
        }
        return csrfCode
    },

    /**
     * 获取uin
     * @method getUin
     * @return {String} uin
     */
    getUin: function () {
        return parseInt((this.cookie.get('uin') || '').replace(/\D/g, ''), 10) || ''
    },

    /**
     * 获取 ownerUin
     */
    getOwnerUin: function () {
        return parseInt((this.cookie.get('ownerUin') || '').replace(/\D/g, ''), 10) || ''
    },

    /**
     * 获取 skey
     */
    getSessionKey: function () {
        var skey = this.cookie.get('skey') || this.cookie.get('p_skey')
        return skey ? decodeURIComponent(skey) : null
    },

    setACSRFToken: function (token) {
        csrfCode = token
    }
}

export default util
